/**
 *  Personal Site: My humble personal homepage, made with a tiny bit but not much care.
 *  <https://github.com/MattIPv4/Personal-Site/>
 *  Copyright (C) 2023 Matt Cowley (MattIPv4) (me@mattcowley.co.uk)
 *
 *  This program is free software: you can redistribute it and/or modify
 *   it under the terms of the GNU General Public License as published
 *   by the Free Software Foundation, either version 3 of the License, or
 *   (at your option) any later version.
 *  This program is distributed in the hope that it will be useful,
 *   but WITHOUT ANY WARRANTY; without even the implied warranty of
 *   MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *   GNU General Public License for more details.
 *  You should have received a copy of the GNU General Public License
 *   along with this program. If not, please see
 *   <https://github.com/MattIPv4/Personal-Site/blob/master/LICENSE.md> or <http://www.gnu.org/licenses/>.
 */

$brand: #0069ff;

@media print {
  html,
  body {
    background: transparent;
    color: #000;
    font-size: 10px;
    height: auto;
    min-height: unset;
  }

  @page {
    size: a4 portait;
    margin: 2em;
  }

  .content {
    display: flex;
    flex-flow: row wrap;
    margin: 0;
    padding: 0;

    a {
      text-decoration: none;

      &,
      &:visited {
        color: $brand;
      }

      &::after {
        content: "";
      }
    }

    h1,
    h3.featured {
      align-self: end;
      margin: 0 0 .1em;
    }

    h1,
    .intro {
      flex-basis: 30%;
    }

    .intro {
      flex-direction: column;
      margin: 0;
      padding: 0 1.5em 0 0;
      order: 2;

      > * {
        flex-basis: auto;
      }

      .main {
        display: flex;
        flex-direction: column;
        margin: 0;

        .items {
          margin: 0;

          > div {
            border-top: 1px solid rgba(0, 0, 0, .2);
            margin: 1em 0;
            width: 100%;

            h4 {
              color: $brand;
              font-size: 1.6em;
              margin: .5em 0;
            }

            h2,
            h3 {
              font-size: 1.2em;
              line-height: 1.2;
              margin: .5em 0;

              em {
                font-size: .85em;
                font-style: italic;
              }
            }
          }
        }

        .email {
          order: -1;

          h3 {
            margin: 0 0 2em;
          }
        }

        .contact {
          border-top: 1px solid rgba(0, 0, 0, .2);
          margin: 1em 0 0;
          padding: 1em 0 0;

          li {
            margin: 0 .75em .75em 0;

            a {
              small {
                opacity: 1;
              }
            }
          }
        }
      }
    }

    h3.featured,
    .projects {
      flex-basis: 70%;
    }

    .projects {
      padding: 1em 0 0;
      order: 2;

      .project {
        .project-info {
          .project-meta {
            .project-role {
              color: $brand;
              font-size: 1.6em;
              position: relative;
              display: inline-block;

              &::before {
                border-top: 1px solid rgba(0, 0, 0, .2);
                content: "";
                display: block;
                height: 1px;
                position: absolute;
                top: -.5em;
                width: 3em;
              }
            }

            .project-title {
              font-size: 1.3em;
              font-weight: 400;
              display: inline-block;

              &::before {
                content: "@ ";
                margin-left: 1.25ch;
              }
            }

            .project-dates {
              .project-end {
                &.active {
                  color: $brand;
                }
              }
            }

            .project-tags {
              &::before {
                content: "(";
              }

              &::after {
                content: ")";
              }

              .project-tag {
                background: transparent;
                border-radius: 0;
                margin: 0;
                padding: 0;

                &:not(:last-child) {
                  padding: 0 1ch 0 0;

                  &::after {
                    content: ", ";
                  }
                }
              }
            }

            .project-details {
              margin: 1em 0;
              padding: 0;
            }
          }

          .project-img {
            display: none;
          }
        }
      }

      .project-desc {
        ul {
          list-style: none;

          li {
            position: relative;

            &::before {
              background: rgba(0, 0, 0, .2);
              content: "";
              display: block;
              height: 1px;
              left: -1em;
              position: absolute;
              top: .5em;
              width: .5em;
            }
          }
        }

        a {
          &::after {
            content: " (" attr(href) ")";
            font-size: .85em;
            font-style: italic;
          }
        }
      }
    }

    footer {
      display: none;
    }
  }

  .toast {
    display: none;
  }
}
