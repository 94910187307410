/**
 *  Personal Site: My humble personal homepage, made with a tiny bit but not much care.
 *  <https://github.com/MattIPv4/Personal-Site/>
 *  Copyright (C) 2023 Matt Cowley (MattIPv4) (me@mattcowley.co.uk)
 *
 *  This program is free software: you can redistribute it and/or modify
 *   it under the terms of the GNU General Public License as published
 *   by the Free Software Foundation, either version 3 of the License, or
 *   (at your option) any later version.
 *  This program is distributed in the hope that it will be useful,
 *   but WITHOUT ANY WARRANTY; without even the implied warranty of
 *   MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *   GNU General Public License for more details.
 *  You should have received a copy of the GNU General Public License
 *   along with this program. If not, please see
 *   <https://github.com/MattIPv4/Personal-Site/blob/master/LICENSE.md> or <http://www.gnu.org/licenses/>.
 */

.projects {
  .project {
    margin: 1em 0 2em;

    .project-info {
      display: flex;
      flex-direction: row;
      border-radius: .25em;
      cursor: pointer;
      margin: -1em;
      padding: 1em 1em 0;
      transition: background-color .2s linear;

      &:hover,
      &:focus {
        background: rgba(255, 255, 255, .05);
      }

      @media only screen and (max-width: 768px) {
        flex-direction: column;
      }

      .project-meta {
        flex: 1 1 auto;
        margin: 0 .5em 0 0;

        @media only screen and (max-width: 768px) {
          margin: 0;
        }

        .project-role {
          font-size: 1.15em;
          margin: .5em 0;
        }

        .project-title {
          font-size: 1.5em;
          font-weight: bold;
          margin: .5em 0;
        }

        .project-dates {
          display: flex;
          flex-flow: row wrap;

          .project-end {
            &.active {
              font-style: italic;
            }
          }
        }

        .project-tags {
          display: flex;
          flex: 0 1 auto;
          flex-flow: row wrap;
          margin: .5em 0;

          .project-tag {
            background: rgba(255, 255, 255, .25);
            border-radius: .25em;
            margin: .5em;
            padding: .5em 1em;
          }
        }

        .project-details {
          border-left: 2px solid rgba(255, 255, 255, .25);
          margin: .5em 0 1em;
          padding: 0 0 0 1em;

          .project-headline,
          .project-link {
            font-size: 1.15em;
            margin: .25em 0;
          }

          .project-link {
            @media only screen and (max-width: 768px) {
              display: flex;
              flex-direction: column;
            }

            a,
            span {
              display: inline-block;
            }

            a {
              @media only screen and (max-width: 768px) {
                margin: .25em 0;
                padding: .25em 0;
                word-break: break-all;
              }
            }

            span {
              @media only screen and (max-width: 768px) {
                display: none;
              }
            }
          }
        }
      }

      .project-img {
        flex: 0 0 auto;
        height: 10em; // Make sure to update images/main.js and templates/project.html if height changes
        margin: .5em;
        max-width: 100%;
        width: auto;

        img {
          height: 100%;
          max-width: 100%;
          object-fit: cover;
          width: auto;
        }
      }
    }

    .project-desc {
      font-size: 1.15em;
      margin: 1.5em 0 0;

      ul {
        list-style: disc;

        li {
          margin: .25em 0 .75em 1em;
        }
      }
    }
  }
}
