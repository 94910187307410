/**
 *  Personal Site: My humble personal homepage, made with a tiny bit but not much care.
 *  <https://github.com/MattIPv4/Personal-Site/>
 *  Copyright (C) 2024 Matt Cowley (MattIPv4) (me@mattcowley.co.uk)
 *
 *  This program is free software: you can redistribute it and/or modify
 *   it under the terms of the GNU General Public License as published
 *   by the Free Software Foundation, either version 3 of the License, or
 *   (at your option) any later version.
 *  This program is distributed in the hope that it will be useful,
 *   but WITHOUT ANY WARRANTY; without even the implied warranty of
 *   MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *   GNU General Public License for more details.
 *  You should have received a copy of the GNU General Public License
 *   along with this program. If not, please see
 *   <https://github.com/MattIPv4/Personal-Site/blob/master/LICENSE.md> or <http://www.gnu.org/licenses/>.
 */

@use "sass:color";

$width: 18rem;
$base: #2f80ed;
$bg: color.adjust(color.adjust($base, $saturation: -75%), $lightness: 50%);
$text: color.adjust(color.adjust(color.invert($bg), $saturation: -90%), $lightness: -25%);
$button: color.adjust($base, $lightness: 15%);

.toast {
  background: $bg;
  border-radius: .25rem;
  bottom: 1rem;
  box-shadow: 4px 4px 0 0 color.adjust($bg, $lightness: -10%);
  color: $text;
  font-size: 1rem;
  line-height: 1.25;
  max-width: $width;
  padding: .75rem;
  position: fixed;
  right: 1rem;
  text-shadow: none;
  transition: opacity 1s ease-in-out;
  width: $width;

  &:not(.scrolled) {
    @media only screen and (max-width: 768px) {
      opacity: 0;
      pointer-events: none;
    }
  }

  > div {
    align-items: flex-start;
    display: flex;
    flex-wrap: nowrap;

    > p {
      flex: 1;
    }

    > a {
      cursor: pointer;
      flex: 0 0 auto;
      font-size: 1.1em;
      line-height: 1;
      margin: 0 0 0 .25rem;
      opacity: .5;
      padding: .15rem .25rem;
      transition: opacity .2s ease-in-out;

      @media only screen and (max-width: 768px) {
        font-size: 1.3em;
      }

      &:hover,
      &:focus {
        opacity: 1;
      }
    }
  }

  > a {
    background: $button;
    border-radius: .25rem;
    color: $text;
    display: block;
    margin: .5rem 0 0;
    padding: .25rem;
    text-align: center;
    text-decoration: none;
    transition: background .2s ease-in-out;

    &:hover,
    &:focus {
      background: color.adjust($button, $lightness: -10%);
    }
  }
}
