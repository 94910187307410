/**
 *  Personal Site: My humble personal homepage, made with a tiny bit but not much care.
 *  <https://github.com/MattIPv4/Personal-Site/>
 *  Copyright (C) 2023 Matt Cowley (MattIPv4) (me@mattcowley.co.uk)
 *
 *  This program is free software: you can redistribute it and/or modify
 *   it under the terms of the GNU General Public License as published
 *   by the Free Software Foundation, either version 3 of the License, or
 *   (at your option) any later version.
 *  This program is distributed in the hope that it will be useful,
 *   but WITHOUT ANY WARRANTY; without even the implied warranty of
 *   MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *   GNU General Public License for more details.
 *  You should have received a copy of the GNU General Public License
 *   along with this program. If not, please see
 *   <https://github.com/MattIPv4/Personal-Site/blob/master/LICENSE.md> or <http://www.gnu.org/licenses/>.
 */

html,
body {
  background: #0d0e1a;
  color: #fff;
  font-family: "Ubuntu Mono", monospace;
  font-size: 16px;
  min-height: 100vh;
}

.twemoji {
  width: 1em;
  vertical-align: text-bottom;
}

[data-show-web="false"] {
  @media screen {
    display: none;
    visibility: hidden;
  }
}

[data-show-print="false"] {
  @media print {
    display: none;
    visibility: hidden;
  }
}

.content {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 3em;

  @media only screen and (max-width: 960px) {
    padding: 1em;
  }

  .intro {
    display: flex;
    flex-grow: 1;
    margin: 0 -1em;

    @media only screen and (max-width: 960px) {
      flex-wrap: wrap;
    }

    .main,
    .rail {
      margin: 0 1em;
    }

    .main {
      flex: 1 1 70%;

      &.full {
        flex-basis: auto;
      }

      .items {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        margin: 0 0 0 -1em;

        @media only screen and (max-width: 960px) {
          margin: 0 -1em;
        }

        > div {
          margin: 0 1em;
        }

        &.hidden-links {
          h2,
          h3,
          h4 {
            a {
              &,
              &:visited {
                color: inherit;
                text-decoration: none;
              }

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }

    .rail {
      flex: 1 1 30%;
    }
  }

  // Hello + name heading
  h1 {
    font-size: 3em;
    font-weight: 400;
    margin: 25px 0;
  }

  // What I do items
  h2 {
    font-size: 2em;
    font-weight: 400;
    margin: 15px 0 5px;
  }

  // What I'm interested in items
  // Email + projects headings
  h3 {
    font-size: 1.5em;
    font-weight: 400;
    margin: 15px 0 5px;
  }

  // What I do / I'm interested in headings
  h4 {
    font-size: 1.6em;
    font-weight: 400;
    margin: 25px 0 5px;
  }

  // Right-rail headings
  h5 {
    font-size: 1.6em;
    font-weight: 400;
    margin: 25px 0 5px;
  }

  a,
  a:visited {
    color: #34d;
    transition: color .2s;
  }

  a:hover,
  a:focus {
    color: #58f;
  }

  .contact {
    display: flex;
    flex-flow: row wrap;
    list-style: none;
    margin-top: 20px;
    padding: 0;

    li {
      display: inline-block;
      font-size: 1.2em;
      font-weight: 400;
      margin: 0 0 10px 10px;

      a {
        small {
          font-size: .95em;
          opacity: .8;
        }

        i {
          font-size: .8em;
          opacity: .8;
        }
      }
    }
  }

  footer {
    margin-top: 40px;

    p {
      margin: 15px 0;
    }

    ul {
      display: block;
      list-style: none;

      li {
        &,
        a {
          display: inline-block;
        }

        a {
          margin: 2px 4px;
          padding: 2px 6px;
        }
      }
    }
  }
}
